import * as ethers from "ethers";
import AccessStaking from "../abis/AccessStaking.json";
import ArbitrumStaking from "../abis/ArbitrumStaking.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import {AbiCoder} from "@ethersproject/abi";

const options = "0x0003010011010000000000000000000000000000ea60";

export async function getMinStakingAmount () {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].STAKING_ADDRESS, AccessStaking, provider);

        return await stakingContract.minStakingAmount();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function hasAddressStaked () {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].STAKING_ADDRESS, AccessStaking, provider);

        return await stakingContract.hasAddressStaked(selectedAccount);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function stakeArbitrumNft (stakeAmount, silverNftId, goldNftId) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].STAKING_ADDRESS, ArbitrumStaking, signer);

        const baseLzeroId = '40245';

        const tx = await stakingContract.bridgeStakingAccess(baseLzeroId, options, silverNftId, goldNftId);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function stake (stakeAmount) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].STAKING_ADDRESS, AccessStaking, signer);

        console.log('Stake amount ' + stakeAmount)
        console.log('selectedAccount ' + selectedAccount)
        const tx = await stakingContract.stake(stakeAmount, selectedAccount);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function bridgeStakingAccess (_destChainIds,  isUnstakeAction) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].STAKING_ADDRESS, AccessStaking, signer);

        const abiCoder = new AbiCoder();
        // get stakeIdx
        const activeStake = await stakingContract.activeStake(selectedAccount);
        const payloadData = abiCoder.encode(
            ["uint"],
            [activeStake.stakeId]
        );

        const arbDestId = '40231'
        const quote = await stakingContract.quote(arbDestId, payloadData, options, false);
        const quoteInWei = quote[0];

        const tx = await stakingContract.bridgeStakingAccess(_destChainIds, options, isUnstakeAction, { value: quoteInWei.toString() });
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function claimFees (erc20TokenAddress) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].STAKING_ADDRESS, AccessStaking, signer);

        const tx = await stakingContract.claimFees(erc20TokenAddress);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}
