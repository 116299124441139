import React, {useEffect, useState} from "react";
import '../assets/scss/_header.scss';
import '../assets/scss/_components.scss';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import Logo from "../assets/images/forcefi-logo.svg";
import {Avatar} from 'antd';
import {useAccount, useConnect} from 'wagmi'
import LogoWhite from "../assets/images/home/logo-white.svg"

import Notifications from "./notifications/Notifications";
import {getLocal} from "web3modal";
import ConnectButton from "../utils/ConnectButton";
import ChainSelector from "./ChainSelector";
import {getUserAvatar} from "../controllers/UserProfileController";
import { useAccountEffect } from 'wagmi'

export default function Header() {

    const location = useLocation();
    const { address, chain } = useAccount();
    const navigate = useNavigate();
    const { isConnected } = useAccount()



    const { connectors, connect } = useConnect()

    const [userAvatar, setUserAvatar] = useState(false);

    const[logoLink] = useState("/");
    useEffect(() => {
        const fetchUserAvatar = async () => {
            try {
                const data = await getUserAvatar(address);
                setUserAvatar(data?.avatarLogo
                     ? data.avatarLogo
                     : "https://www.pngall.com/wp-content/uploads/12/Avatar-PNG-Image.png");
            } catch (error) {
                setUserAvatar("https://www.pngall.com/wp-content/uploads/12/Avatar-PNG-Image.png");
                console.error("Failed to fetch user avatar:", error);
            }
        };
        fetchUserAvatar();
    }, [address])

    // useAccountEffect({
    //     onConnect(data) {
    //         console.log("DATA " + data.isReconnected)
    //     //     // console.log('Connected', { address, connector, isReconnected })
    //         if (!data.isReconnected){
    //             if (getLocal("homePageNavigation") === true){
    //                 navigate('/registration')
    //             } else {
    //                 navigate('/cabinet')
    //             }
    //             window.location.reload()
    //         }
    //     },
    //     onDisconnect() {
    //         localStorage.removeItem('authToken');
    //         localStorage.removeItem('expirationDate');
    //         localStorage.removeItem('homePageNavigation');
    //         console.log('Disconnected')
    //         navigate('/');
    //         window.location.reload()
    //     },
    // })

    const isHome = location.pathname === '/' || location.pathname === '/forc-sale';
    const getLinkClass = (path) => {
        const home = isHome ? ' home' : '';
        const active = location.pathname === path ? ' active' : '';
        return '-item' + home + active ;
    };

    return (
    <div className={`navbar ${isHome ? 'home' : ''}`}>
        <NavLink to={logoLink} className='-index'>
            <img
                src={isHome ? LogoWhite : Logo}
                alt=''
                className={`-logo ${isHome ? 'home' : ''}`}
            />
        </NavLink>
        <div className='-nav-wrapper'>
            <div className='-nav-items left' />
            <div className='-nav-items right'>
                <div className='-desktop'>
                    {isHome
                        ? <>
                            <Link className={getLinkClass('/projects')} to='/projects'>Projects</Link>
                            <Link className={getLinkClass('/curators')} to='/curators'>Curators</Link>
                            <Link className={getLinkClass('/partners')} to='/partners'>Partners</Link>
                        </>
                        : <>
                            <Link className={getLinkClass('/registration')} to='/registration'>List project</Link>
                            <Link className={getLinkClass('/projects')} to='/projects'>Projects</Link>
                            <Link className={getLinkClass('/curators')} to='/curators'>Curators</Link>
                            <Link className={getLinkClass('/referral')} to='/referral'>Referrals</Link>
                            <Link className={getLinkClass('/partners')} to='/partners'>Partners</Link>
                            <Link className={getLinkClass('/staking')} to='/staking'>Staking</Link>
                        </>
                    }
                    {location.pathname !== "/forc-sale" &&
                        <Link to='/forc-sale'>
                            <button className={isConnected || !isHome  ? "app-button forc-sale connected" : "app-button forc-sale "}>SALE</button>
                        </Link>
                    }
                </div>
                {isConnected
                    ? <>
                        {!isHome && <Notifications /> }
                        <Link to='/cabinet'><Avatar src={userAvatar} /></Link>
                    </>
                    : isHome
                        ? <ConnectButton buttonType="app-button" homePageNavigation={false} buttonName="Get Started" />
                        : <ConnectButton buttonType={"web3Button"} homePageNavigation={false} buttonName={address}/>
                }
                {chain && !isHome && <ChainSelector />}
                {isConnected &&
                    <ConnectButton
                        buttonType="web3Button"
                        homePageNavigation={false}
                        buttonName={address}
                    />
                }
            </div>
        </div>
    </div>
    );
}
