import React from "react";
import { Modal, List, Typography, Tag, Avatar, Divider, Space, Card, Row, Col, Tooltip, Button } from "antd";
import { CalendarOutlined, LinkOutlined, InfoCircleOutlined, CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';


const { Text, Title, Paragraph } = Typography;

function FundraisingDetailsModal({ show, close, fundData, projectName }) {
    if (!fundData) return null;

    const getStatusConfig = (status) => {
        switch (status) {
            case "ACTIVE_SALE":
                return { color: "green", icon: <CheckCircleOutlined />, text: "Active Sale" };
            case "PRE_SALE":
                return { color: "orange", icon: <ClockCircleOutlined />, text: "Pre Sale" };
            case "POST_SALE":
                return { color: "purple", icon: <InfoCircleOutlined />, text: "Post Sale" };
            default:
                return { color: "blue", icon: <InfoCircleOutlined />, text: status };
        }
    };

    const statusConfig = getStatusConfig(fundData.status);

    const formatAddress = (address) => {
        if (!address || address.length < 10) return address;
        return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
    };

    return (
        <Modal
            title={
                <Space size="middle" align="center">
                    <Avatar 
                        size={40} 
                        src={fundData.image || null}
                        style={{ backgroundColor: !fundData.image ? "#1890ff" : "transparent" }}
                    >
                        {!fundData.image && fundData.title ? fundData.title.charAt(0).toUpperCase() : "F"}
                    </Avatar>
                    <div>
                        <Title level={4} style={{ margin: 0 }}>{fundData.title || "Fundraising Details"}</Title>
                        <Tag color={statusConfig.color} icon={statusConfig.icon}>{statusConfig.text}</Tag>
                    </div>
                </Space>
            }
            open={show}
            onCancel={close}
            footer={[
                <Link to={'/project?id=' + projectName}>
                <Button key="close" onClick={close}>
                    Go To Project
                </Button>
                </Link>
            ]}
            width={600}
            bodyStyle={{ padding: "12px 24px" }}
        >
            <Card
                style={{ marginBottom: 16, borderRadius: 8 }}
                bodyStyle={{ padding: 16 }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Text type="secondary">Network</Text>
                        <Paragraph strong>{fundData.network || "N/A"}</Paragraph>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Address</Text>
                        <Paragraph strong>
                            <Tooltip title={fundData.address}>
                                {formatAddress(fundData.address) || "N/A"}
                            </Tooltip>
                            {fundData.address && (
                                <Button 
                                    type="link" 
                                    icon={<LinkOutlined />} 
                                    size="small"
                                    onClick={() => navigator.clipboard.writeText(fundData.address)}
                                    style={{ marginLeft: 4, padding: 0 }}
                                />
                            )}
                        </Paragraph>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary"><CalendarOutlined /> Start Date</Text>
                        <Paragraph strong>
                            {fundData.startDate ? new Date(fundData.startDate).toLocaleString() : "N/A"}
                        </Paragraph>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary"><CalendarOutlined /> End Date</Text>
                        <Paragraph strong>
                            {fundData.endDate ? new Date(fundData.endDate).toLocaleString() : "N/A"}
                        </Paragraph>
                    </Col>
                </Row>
            </Card>

            {fundData.description && (
                <Card
                    title="Description"
                    style={{ marginBottom: 16, borderRadius: 8 }}
                    bodyStyle={{ padding: 16 }}
                >
                    <Paragraph>{fundData.description}</Paragraph>
                </Card>
            )}

            {fundData.keyBenefits?.length > 0 && (
                <Card
                    title="Key Benefits"
                    style={{ borderRadius: 8 }}
                    bodyStyle={{ padding: 16 }}
                >
                    <List
                        dataSource={fundData.keyBenefits}
                        renderItem={(item, index) => (
                            <List.Item style={{ padding: "8px 0", borderBottom: index === fundData.keyBenefits.length - 1 ? 'none' : '1px solid #f0f0f0' }}>
                                <Space>
                                    <CheckCircleOutlined style={{ color: '#52c41a' }} />
                                    <Text>{item}</Text>
                                </Space>
                            </List.Item>
                        )}
                    />
                </Card>
            )}
        </Modal>
    );
}

export default FundraisingDetailsModal;