import { useState } from "react";
import {Typography, Select, Button, Space, Input, Card} from "antd";
import {SwapOutlined, TrophyOutlined} from "@ant-design/icons";
import {chainIcons} from "../../utils/chainIcons";
import {useAccount, useSwitchChain} from "wagmi";
import {bridgeStakingAccess, stake} from "../../ethers/Staking";
import {approveAmount} from "../../ethers/ERC20Contract";
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";

const { Text } = Typography;

const tierOptions = [
    { value: "Silver", label: "Silver Tier" },
    { value: "Gold", label: "Gold Tier" },
];

const categoryOptions = [
    { value: 500, label: "Launchpad" },
    { value: 3000, label: "Curator" },
    { value: 15000, label: "Fee Earn" },
];

export default function AccessStaking() {
    const [selectedTier, setSelectedTier] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [nftAmount, setNftAmount] = useState("");
    const [unstakeAmount, setUnstakeAmount] = useState("");

    const [selectedChain, setSelectedChain] = useState(null);
    const { chains } = useSwitchChain();
    const { chain } = useAccount();

    const handleBridgeAccess = async () => {
        if (selectedChain) {
            await bridgeStakingAccess(selectedChain, false)
        }
    };

    const handleUnstake = async () => {
        if (selectedChain) {
            await bridgeStakingAccess([], true)
        }
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value;
        if (!isNaN(value) && Number(value) >= 0) {
            setter(value);
        }
    };

    const handleAccessStake = async () => {
        await approveAmount(
            erc20InvestmentTokens["11155111"].FORC_TOKEN_ADDRESS,
            erc20InvestmentTokens["11155111"].STAKING_ADDRESS,
            selectedCategory,
            18
        );
        await stake(selectedCategory)
    };

    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div>
                <Text strong>Forcefi NFT Staking</Text>
                <Select
                    style={{ width: '100%', marginTop: 8 }}
                    placeholder="Select Tier"
                    value={selectedTier}
                    onChange={setSelectedTier}
                    options={tierOptions}
                />

                <Input
                    style={{ marginTop: 8 }}
                    placeholder="Enter NFT amount"
                    type="number"
                    min="0"
                    value={nftAmount}
                    onChange={handleInputChange(setNftAmount)}
                />
                <Button
                    type="primary"
                    style={{ marginTop: 8 }}
                    disabled={!nftAmount || !selectedTier}
                >
                    Stake NFT
                </Button>
            </div>

            <div>
                <Text strong>Unstake NFT</Text>
                <Input
                    style={{ marginTop: 8 }}
                    placeholder="Enter unstake amount"
                    type="number"
                    min="0"
                    value={unstakeAmount}
                    onChange={handleInputChange(setUnstakeAmount)}
                />
                <Button
                    type="primary"
                    style={{ marginTop: 8 }}
                    disabled={!unstakeAmount}
                >
                    Unstake NFT
                </Button>
            </div>
            <div>
                <Text strong>FORC Token Staking</Text>
                <Select
                    style={{ width: '100%', marginTop: 8 }}
                    placeholder="Select Category"
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                    options={categoryOptions}
                />

                <Button
                    type="primary"
                    style={{ marginTop: 8 }}
                    disabled={!selectedCategory}
                    onClick={handleAccessStake}
                >
                    Stake
                </Button>
            </div>

            <Card className="bridge-card" style={{ maxWidth: '42rem' }}>
                <div style={{ marginTop: -16 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <p style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.45)', textAlign: 'center' }}>
                            Bridge your access token to another blockchain network. This allows you to operate and get fees on multiple chains.
                        </p>
                        <div className='chain-select center'>
                            {chains.filter(c=> c.name !== chain.name).map((chain) =>
                                <div
                                    key={chain.id}
                                    onClick={() => setSelectedChain(chain.id)}
                                    className={chain.id === selectedChain ? "-item active" : "-item"}
                                >
                                    <img
                                        src={chainIcons[chain.name]}
                                        alt={chain.name}
                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                    />
                                    <span>{chain.name}</span>
                                </div>
                            )}
                        </div>
                        <div className='center'>
                            <Button
                                type="primary"
                                icon={<SwapOutlined />}
                                onClick={handleBridgeAccess}
                                disabled={!selectedChain}
                            >
                                Bridge Access
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>

            <Button type="primary" block icon={<TrophyOutlined />}>
                Claim fees
            </Button>
        </Space>
    );
}
