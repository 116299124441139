const erc20InvestmentTokens = {
    // SEPOLIA
    11155111: {
        USDT: "0xC09291B33A6E7ba4D7c293D58a6683C1f9F2946C",
        USDC: "0x3a9725495A7552a4de196962784ba073f0ac89fc",
        DAI: "0xdf3477E7a8C21dAa0AB5B8089466FfFAD53d8B3b",

        FUNDRAISING_ADDRESS: "0x5622023bcAe407681F387852b3df62beCeA24950",
        VESTING_ADDRESS: "0x52Bf0Ba9Fc2C6Bd6708375d05751649D94358022",
        PACKAGE_ADDRESS: "0x80fBEF0fb55885DAbfa38F0f3A5bF01A00F96b97",
        STAKING_ADDRESS: "0xbC48106039FaBA06BD5777a68bD24f85ddac878e",
        FORC_TOKEN_ADDRESS: "0x290E374362b6d36A0cfF86d682Db57845c913657",

        SIMPLE_FACTORY_ADDRESS: "0xE15FE802d0216Ea768971462D637276DA9Aa6C75",
        PAUSABLE_FACTORY_ADDRESS: "0xb52143afb39c77C1294477caF8d8075fc9c5fC38",

        LZERO_CHAIN_ID: "40161"
    },

    // ARBITRUM-SEPOLIA
    421614: {
        USDT: "0x83e187439137624303F1e889623a56F01A612854",
        USDC: "0xAd9E891a0F259336927d84a6183C77115f7bF651",
        DAI: "0x31da54BC8465a29e07b3896D53B9b81F3b88dEde",

        FUNDRAISING_ADDRESS: "0x80fBEF0fb55885DAbfa38F0f3A5bF01A00F96b97",
        VESTING_ADDRESS: "0x79BebF5c295DA7F4Eb0061146a9bc4D962E44843",
        PACKAGE_ADDRESS: "0xb0DccCa37b9901d23908fB114c15ba500652a82a",
        STAKING_ADDRESS: "0xD0dDfC08e77C7AD07bf31C4bA680AF67954127d8",

        SIMPLE_FACTORY_ADDRESS: "0xA5D1780C7eBEE53f41643E823a2e841515032Ebf",
        PAUSABLE_FACTORY_ADDRESS: "0x794442Ec88440f41924390f8734FF70d67fdA0b1",

        LZERO_CHAIN_ID: "40231"
    },

    // LINEA-SEPOLIA
    59141: {
        USDT: "0x594819c1907a9c93E0dc780EBA152CFD8405c739",
        USDC: "0x11A6a12e790575559422a056C3e4384C80345E66",
        DAI: "0xD79Bed02BA23b76125fA9F75abF4A940CE9887D8",

        FUNDRAISING_ADDRESS: "0x83F994520bBf9A3E05eb3355B869fE2a19A93fB2",
        VESTING_ADDRESS: "0x794442Ec88440f41924390f8734FF70d67fdA0b1",
        PACKAGE_ADDRESS: "0x811E4d08DA26DeD71023668D91A48F7d7Ab01995",

        SIMPLE_FACTORY_ADDRESS: "0x80fBEF0fb55885DAbfa38F0f3A5bF01A00F96b97",
        PAUSABLE_FACTORY_ADDRESS: "0xd882651a259bbfB7C23d44Db3D4bA95f78d434Df",
    },

};

export default erc20InvestmentTokens;
