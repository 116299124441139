import * as ethers from "ethers";
import { AbiCoder } from "@ethersproject/abi";
import ForcefiPackage from "../abis/ForcefiPackage.json";
import LayerZeroBase from "../abis/LayerZeroBase.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import ContractFactory from "../abis/ContractFactory.json";
import Web3 from "web3";

export async function buyPackage (projectName, packageLabel, investmentTokenAddress, referralAddress) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork()

        const packageContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, signer);

        let tier;

        const tx = await packageContract.buyPackage(projectName, packageLabel, investmentTokenAddress, referralAddress);

        await tx.wait();

        const specificFilter = packageContract.filters.PackageBought(null, null, selectedAccount);

        const matchedEvents = await packageContract.queryFilter(specificFilter, null, "latest")
        for(const event of matchedEvents){
            const projectNameFromChain = event.args[0];
            if (projectNameFromChain === projectName) {
                tier = event.args[1];
            }
        }

        return tier;

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function bridgeToken (destChainNetworkId, projectName) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork()

        const destChainId = erc20InvestmentTokens[destChainNetworkId].LZERO_CHAIN_ID;

        const packageContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, signer);

        const abiCoder = new AbiCoder();
        const payloadData = abiCoder.encode(
            ["string"],
            [projectName]
        );
        const options = "0x0003010011010000000000000000000000000000ea60";

        const quote = await packageContract.quote(destChainId, payloadData, options, false);

        const quoteInWei = quote[0];

        const tx = await packageContract.bridgeToken(destChainId, projectName, options, { value: quoteInWei.toString() });

        await tx.wait();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getChainlinkDataFeedLatestAnswer (investmentTokenAddress) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);

        const network = await provider.getNetwork();

        const erc20TokenContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, provider);

        return await erc20TokenContract.getChainlinkDataFeedLatestAnswer(investmentTokenAddress);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function hasCreationToken (projectName) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);

        const network = await provider.getNetwork();

        const erc20TokenContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, provider);

        return await erc20TokenContract.hasCreationToken(selectedAccount, projectName);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}


