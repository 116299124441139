import React, { useEffect, useState } from 'react';
import { Table, Typography, Tag, Space, Button } from 'antd';
import {
    DollarOutlined,
    RocketOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { getVestingsByBeneficiaryAddress } from "../../controllers/VestingController";
import { getContributorByAddress } from "../../controllers/ContributorController";
import ShortAddress from '../elements/ShortAddress';
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";

const { Text } = Typography;

export default function MyTokens() {
    const { address } = useAccount();
    const [vestingTokens, setVestingTokens] = useState([]);
    const [saleTokens, setSaleTokens] = useState([]);
    const [loadingVestings, setLoadingVestings] = useState(true);
    const [loadingSales, setLoadingSales] = useState(true);

    const transformData = (data) => {
        if (!Array.isArray(data)) {
            console.error("Invalid data format: Expected an array");
            return [];
        }

        return data.flatMap(item =>
            item.entries.map(entry => ({
                _id: item._id,
                contributorAddress: item.contributorAddress,
                network: entry.network,
                eventIdx: entry.eventIdx,
                projectName: entry.projectName,
                eventType: entry.eventType,
                totalAmount: entry.totalAmount,
                entryId: entry._id,
                __v: item.__v
            }))
        );
    };

    useEffect(() => {
        const fetchVestingTokens = async () => {
            try {
                setLoadingVestings(true);
                const beneficiaryVestings = await getVestingsByBeneficiaryAddress(address);
                setVestingTokens(beneficiaryVestings.data);
            } catch (error) {
                console.error('Error fetching vesting tokens:', error);
            } finally {
                setLoadingVestings(false);
            }
        };

        const fetchSaleTokens = async () => {
            try {
                setLoadingSales(true);
                const saleTokensData = await getContributorByAddress(address);
                const transformedData = transformData(saleTokensData);
                setSaleTokens(transformedData);
            } catch (error) {
                console.error('Error fetching sale tokens:', error);
            } finally {
                setLoadingSales(false);
            }
        };

        fetchVestingTokens();
        fetchSaleTokens();
    }, [address]);

    const formatTokenAmount = (amount) => {
        return (Number(amount) / 1e18).toFixed(2);
    };

    const vestingColumns = [
        {
            title: 'Project',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                <Space>
                    {text}
                    <Tag color="blue">{erc20InvestmentTokens[record.chain].NAME}</Tag>
                </Space>
            )
        },
        {
            title: 'Token Address',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress',
            render: (address) => <ShortAddress address={address} />
        },
        {
            title: 'Vesting Index',
            dataIndex: 'vestingIdx',
            key: 'vestingIdx',
            render: (index) => <ShortAddress address={index} />
        },
        {
            title: 'Total Amount',
            dataIndex: 'tokenAmount',
            key: 'tokenAmount',
            render: (amount) => (
                <Space>
                    <DollarOutlined />
                    <Text>{amount}</Text>
                </Space>
            )
        },
        {
            title: 'Released Tokens',
            dataIndex: 'tokensReleased',
            key: 'releasedTokens',
            render: (released) => (
                <Space>
                    <RocketOutlined />
                    <Text type={released > 0 ? 'success' : 'secondary'}>
                        {formatTokenAmount(released)} tokens
                    </Text>
                </Space>
            )
        }
    ];

    const saleColumns = [
        {
            title: 'Project',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (amount) => (
                <Space>
                    <DollarOutlined />
                    <Text>{formatTokenAmount(amount)}</Text>
                </Space>
            )
        },
        {
            title: '',
            key: 'action',
            render: (text, saleTokens) => (
                <Link to={`/project?id=`+ saleTokens.projectName}>
                    <Button style={{ width: '120px' }}>
                        Go To Project
                    </Button>
                </Link>
            ),
        },

    ];

    return (
        <div>
            <Typography.Title level={4}>Vesting Tokens</Typography.Title>
            <Table
                columns={vestingColumns}
                dataSource={vestingTokens}
                rowKey="_id"
                loading={loadingVestings}
                locale={{ emptyText: 'No vesting tokens found' }}
                pagination={{ pageSize: 5, showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} tokens` }}
            />
            <Typography.Title level={4}>Sale Tokens</Typography.Title>
            <Table
                columns={saleColumns}
                dataSource={saleTokens}
                rowKey="_id"
                loading={loadingSales}
                locale={{ emptyText: 'No sale tokens found' }}
                pagination={{ pageSize: 5, showSizeChanger: true, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} tokens` }}
            />
        </div>
    );
}
