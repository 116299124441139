import React, {useState} from 'react';
import "../../assets/scss/_company-card.scss"
import Ethereum from "../../assets/icons/header/ethereum.svg";
import Binance from "../../assets/icons/header/binance.svg";
import PolygonMumbai from "../../assets/icons/header/polygonMumbai.svg";
import Arbitrum from "../../assets/icons/arbitrum.svg";
import {useNavigate} from "react-router-dom";
import Optimism from "../../assets/icons/header/optimism.svg";
import Zksync from "../../assets/icons/header/zksync.svg"
import axios from "axios";
import "../../assets/scss/_modal.scss"
import "../../assets/css/spinner.css";
import {useCuratorsAccess} from "../../contexts/CuratorsAccess";
import {Tag, Modal} from "antd";
import FundraisingModal from '../modals/FundraisingDetailsModal';
import { UserOutlined, GlobalOutlined, ReadOutlined } from '@ant-design/icons';

const cardsChainsText = {
    "homestead": "Ethereum",
    "goerli": "Ethereum",
    "bsc": "Binance",
    "matic": "Polygon",
    "arbitrum": "Arbitrum",
    "arbitrum-goerli" : "Arbitrum",
    "optimism-goerli": "Optimism",
    "optimism": "Optimism",
    "zksync": "Zksync",
};

const cardsChainsIcon = {
    "homestead": Ethereum,
    "goerli": Ethereum,
    "bsc" : Binance,
    "matic" : PolygonMumbai,
    "arbitrum" : Arbitrum,
    "arbitrum-goerli" : Arbitrum,
    "optimism-goerli": Optimism,
    "optimism": Optimism,
    "zksync": Zksync,
};

const fundraisingStatus = {
    "POST_SALE": "Complete",
    "PRE_SALE": "Pre sale",
    "ACTIVE_SALE": "Active"
};

const fundraisingColors = {
    "POST_SALE": "purple",   
    "PRE_SALE": "orange",    
    "ACTIVE_SALE": "green"   
};

function CompanyCuratorsModal(props) {
    return (
        <Modal
            title=""
            visible={props.show}
            onCancel={props.close}
            footer={null}
            width={500}
            centered
            style={{
                maxWidth: 500,
                maxHeight: '60vh',
                overflowY: 'auto',
            }}
        >
            <div className='modal-content small-margin'>
                {props.curatorsList.length === 0 ? (
                    <div>Company has no curators</div>
                ) : (
                    <div>{props.curatorsList}</div>
                )}
            </div>
        </Modal>
    );
}



export default function CompanyCard({item, key, enabledLink = true, curatorButton =true}) {
    const [linkIsHovered, setLinkIsHovered] = useState(false);
    const [fundraisingsModal, setFundraisingsModal] = useState(false);
    const [fundraisingsData, setFundraisingsData] = useState(false);
    const [anyModalOpen, setAnyModalOpen] = useState(false);

    const navigate = useNavigate();
    const handleOpenDfo = (wallet, activated, network) => {
        if (!anyModalOpen) {
            navigate('/project?id=' + wallet + "&&chain=" + network);
        }
    };
    
    const handleShowFundraisingInfo = (fundraising) => {
        setFundraisingsModal(true);
        setAnyModalOpen(true)
        setFundraisingsData(fundraising.entries[0]);
        console.log(fundraising.entries);
    };
    

    const closeFundraisingModal = () => {
        setFundraisingsModal(false);
        setAnyModalOpen(false);
    };
    
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [curatorsList, setCuratorsList] = useState(<div className='lds-dual-ring'></div>);
    const handleGetCurators = async () => {
        setCompanyCuratorsModal(true);
        setAnyModalOpen(true); 
        
        try {
            console.log(item.companyName)
            await axios.get(apiUrl + '/api/getMatchedCurators', {params : {projectName : item.companyName}})
                .then(response => {
                    setCuratorsList(response.data.map((item, key) =>
                        <div className='company-curators' key={key}>
                            <img className='-img' src={item.avatarLogo} alt=""/>
                            <div className='-text'>{item.nickName}</div>
                        </div>))
                })
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle 401 Unauthorized error here
                setCuratorsList(<div>Unauthorized - This wallet doesn't have access</div>)
            } else {
                // Handle other errors
                setCuratorsList(<div>An error occurred: {error.response}</div>)
            }
        }
    }
    
    const [companyCuratorsModal, setCompanyCuratorsModal] = useState(false);
    
    const closeCompanyCuratorsModal = () => {
        setCompanyCuratorsModal(false);
        setAnyModalOpen(false);
    };
    
    const getFundraisingTagColor = (status) => {
        return fundraisingColors[status] || "blue"; 
    };
    
    const {hasCurratorAccess} = useCuratorsAccess();
    return (
        <div className='company-card' key={key}
             onClick={() => enabledLink && !linkIsHovered && handleOpenDfo(item.companyName, item.activated, item.network)}>
            <div className="-header-container">
                <div className="-main-info">
                    {item.logo === "" ?
                        <div className="-img"></div>
                        :
                        <img src={item.logo} className='-img' alt=""/>
                    }
                    <div className='-text xl'>{item.companyName}</div>
                    <Tag color="blue" >{item.listingTier}</Tag>
                </div>
                <div className="-links">
                    {curatorButton && hasCurratorAccess &&
                        <Tag className='-role'
                            onMouseEnter={() => setLinkIsHovered(true)}
                            onMouseLeave={() => setLinkIsHovered(false)}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleGetCurators();
                            }}
                        >
                            <UserOutlined />
                        </Tag>
                    }
                    {item.websiteUrl !== "" && item.websiteUrl !== undefined &&
                        <a href={item.websiteUrl} target='_blank' rel="noreferrer">
                            <Tag
                            onMouseEnter={() => setLinkIsHovered(true)}
                            onMouseLeave={() => setLinkIsHovered(false)}
                            >
                                <GlobalOutlined />
                            </Tag>
                        </a>
                    }
                    {item.documentation !== "" && item.documentation !== undefined &&
                        <a href={item.documentation} target='_blank' rel="noreferrer">
                            <Tag
                            onMouseEnter={() => setLinkIsHovered(true)}
                            onMouseLeave={() => setLinkIsHovered(false)}
                            target='_blank'
                            >
                                <ReadOutlined />
                            </Tag>
                        </a>
                    }

                </div>
            </div>
            <div className='-text elevator'>{item.elevatorPitch}</div>


            {item?.projectFundraisings?.length > 0 && <>
            <div className='-text s'>
                Fundraisings:
            </div>

            <div className='-text s'>
                { item.projectFundraisings.map((fundraisingItem, index) => {
                    const status = fundraisingItem.entries[0].status;
                    return (
                        <Tag 
                            key={index}
                            color={getFundraisingTagColor(status)}   
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleShowFundraisingInfo(fundraisingItem);
                            }}
                            onMouseEnter={() => setLinkIsHovered(true)}
                            onMouseLeave={() => setLinkIsHovered(false)}>
                            {fundraisingStatus[status]}
                        </Tag>
                    );
                })}
                </div>
                </>
            }
            <FundraisingModal show={fundraisingsModal} close={closeFundraisingModal} fundData={fundraisingsData} projectName={item.companyName} />
            <CompanyCuratorsModal show={companyCuratorsModal} close={closeCompanyCuratorsModal} curatorsList={curatorsList}/>
        </div>
    );
}